<template>
	<div class="page">
		<div class="view">
			<div class="viewT">
				<div style="font-weight: bold;">新建盘点</div>
				<el-button style="width: 110px;" @click="handleBackClick">返回</el-button>
			</div>
			<div class="search">
				<el-button type="primary" @click="dialogProduct_state = true">选择盘点商品</el-button>
			</div>
			<div class="viewC">
				<el-table :data="tableData">
					<el-table-column prop="newData.barCode" label="条形码">
					</el-table-column>
					<el-table-column prop="newData.productName" label="名称">
					</el-table-column>
					<el-table-column prop="newData.modelValue" label="规格">
					</el-table-column>
					<el-table-column prop="newData.originalPrice" label="零售价" width="100">
					</el-table-column>
					<el-table-column prop="newData.currentStock" label="当前库存" width="100">
					</el-table-column>
					<el-table-column label="实际库存">
						<template slot-scope="scope">
							<el-input-number v-model="scope.row.newData.count" :min="1" size="small"></el-input-number>
						</template>
					</el-table-column>
					<el-table-column label="备注">
						<template slot-scope="scope">
							<el-input v-model="scope.row.newData.remark" placeholder="请输入备注" clearable size="small">
							</el-input>
						</template>
					</el-table-column>
					<el-table-column label="操作" width="80">
						<template slot-scope="scope">
							<el-button @click="handleDeleteClick(scope.$index)" type="text" size="small">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="viewB">
				<el-button style="width: 110px;" @click="dialogRemark_state = true">备注</el-button>
				<el-button type="primary" style="width: 110px;margin-right: 20px;" @click="handleSubmitClick">提交</el-button>
			</div>
		</div>
		<div v-if="dialogProduct_state">
			<dialogProduct @submit="submit_dialogProduct" :tree="tree" :selectedList="tableData"></dialogProduct>
		</div>
		<div v-if="dialogRemark_state">
			<dialogRemark @submit="submit_dialogRemark" :remarkType="remarkType" :remark="remark"></dialogRemark>
		</div>
	</div>
</template>

<script>
	import dialogProduct from "../../../components/dialogPandianGoods.vue"
	import dialogRemark from "../../../components/dialogRemark.vue"
	export default{
		components: {
			dialogProduct,
			dialogRemark
		},
		data() {
			return {
				tableData:[],
				dialogProduct_state: false, //商品弹框
				dialogRemark_state: false, //备注弹框
				tree: [{code:'goodstype'}],
				remarkType:2,  //备注类型
				remark:'',  //整体备注
			}
		},
		mounted() {
			
		},
		methods:{
			//提交
			handleSubmitClick() {
				if(this.tableData.length == 0){
					this.$message({
						type: 'error',
						message: '请选择盘点商品'
					});
					return
				}
				let arr = [];
				this.tableData.map((item)=>{
					arr.push(item.newData)
				})
				let params = {
					remark:this.remark,
					lstOrderItem:arr,
				}
				this.$http.post("/store/inventory/inventory",params).then(res => {
					if (res.code === 0) {
						this.$message({
							type: 'success',
							message: '已提交'
						});
						this.$router.go(-1);
					}
				})
			},
			//获取弹框
			submit_dialogRemark(obj){
				this.remark = obj.data;
				this.dialogRemark_state = false;
			},
			//删除
			handleDeleteClick(index){
				console.log(index)
			},
			//商品确认
			submit_dialogProduct(obj) {
				console.log(obj,'objobj');
				this.tableData = obj.data;
				this.dialogProduct_state = false;
			},
			//跳转新建商品
			handleAddGoodsClick() {
				this.$router.push({
					path: 'goodsDetail',
					query: {
						editMsg: null
					}
				})
			},
			//返回
			handleBackClick() {
				this.$confirm('是否返回上一页?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$router.go(-1);
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
				});
			},
		}
	}
</script>

<style lang="scss" scoped>
	.page {
		width: 100%;
		height: 100%;
		color: $fontColor;
	
		.view {
			background: #fff;
			height: 100%;
	
			border-radius: 20px;
			display: flex;
			flex-direction: column;
	
			.viewT {
				box-sizing: border-box;
				height: 68px;
				display: flex;
				align-items: center;
				border-bottom: solid 1px #F2F2F2;
				padding: 0 20px;
				justify-content: space-between;
			}
	
			.search {
				display: flex;
				align-items: center;
				height: 80px;
				font-size: 14px;
				padding: 0 20px;
			
				.serchName {
					margin-right: 12px;
				}
			}
			
			.viewC {
				flex: 1;
				overflow-y: scroll;
			}
	
			.viewB {
				height: 68px;
				display: flex;
				justify-content: flex-end;
				align-items: center;
				box-sizing: border-box;
				border-top: solid 1px #F2F2F2;
			}
	
			.viewC::-webkit-scrollbar {
				display: none;
			}
		}
	}
</style>
